import dynamic from 'next/dynamic';

import { ComponentType, useMemo } from 'react';

import { useSelfKarmaQuery } from '@query';

import { DeserializedUser, MEMBERSHIP_TIER_ID, UserKarma } from '@api';

import { useUserAdSettings } from '@shared/common/components/NavLayout/AdBlocks';
import ProfileMenuDialogKyc from '@shared/common/components/NavLayout/ProfileMenuDialogKyc';
import { nextProjectUrl } from '@shared/constants/url';

import * as Dialog from '@radix-ui/react-dialog';
import { Karma, Membership } from '@ui/uikit/components/icons/mono';
import { CrossLg } from '@ui/uikit/components/icons/mono/CrossLg';
import { Sqr as MonoSqr } from '@ui/uikit/components/icons/mono/Sqr';
import { cn, formatReward } from '@ui/uikit/utils';
import { Sqr, USDT } from '@ui-uikit/lib/components/icons/poly';

import { useNavLayout } from './NavLayout';
import NavLayoutLink from './NavLayoutLink';
import { NavLayoutLinkBase, NavLayoutUser } from './types';
import UserAvatar from './UserAvatar';
import { getMembershipClassNames, getMembershipName } from './utils';

const GlobalInlineProfileMiddleBanner = dynamic(
  () => import('@shared/common/components/NavLayout/AdBlocks/GlobalInlineProfileMiddleBanner'),
  {
    ssr: false,
  },
);

export type ProfileNavItem = NavLayoutLinkBase & {
  hidden?: (data: { user: NavLayoutUser }) => boolean;
};

export type ProfileNavGroup = {
  id: string;
  className?: string;
  items: ProfileNavItem[];
  hidden?: (data: { user: NavLayoutUser }) => boolean;
};

export const profilePublicNav: ProfileNavGroup = {
  id: 'public',
  items: [
    {
      id: 'karma-and-rewards',
      label: 'Karma & Rewards',
      href: nextProjectUrl.store('/profile/karma'),
    },
    {
      id: 'magic-id',
      label: 'Magic ID',
      href: nextProjectUrl.store('/profile/magic-id'),
    },
    {
      id: 'magic-membership',
      label: 'Magic Membership',
      href: nextProjectUrl.store('/profile/membership/dashboard'),
    },
    {
      id: 'invite-friends',
      label: 'Invite Friends',
      href: nextProjectUrl.store('/refer-a-friend'),
    },
  ],
};

export const profileDeveloperNav: ProfileNavGroup = {
  id: 'developer',
  hidden: ({ user }) => !user.data.attributes.creatorId,
  items: [
    {
      id: 'settings',
      label: 'Developer Profile',
      href: nextProjectUrl.store('/profile/edit-developer-profile'),
    },
    {
      id: 'my-applications',
      label: 'My Applications',
      href: nextProjectUrl.store('/profile/developer-dapps'),
    },
    // TODO: add href
    // {
    //   id: 'ad-promotions',
    //   label: 'Ad Promotions',
    // },
    {
      id: 'campaign-review',
      label: 'Campaign Review',
      href: nextProjectUrl.store('/profile/developer-campaign'),
    },
    {
      id: 'score-widget',
      label: 'Score Widget',
      href: nextProjectUrl.store('/profile/developer-info/widget'),
    },
  ],
};

export const becomeDeveloperLink: ProfileNavItem = {
  id: 'become-developer',
  href: nextProjectUrl.store('/profile/edit-developer-profile'),
  label: 'Become Developer',
  hidden: ({ user }) => Boolean(user.data.attributes.creatorId),
};

const profileMenuNav: ProfileNavGroup[] = [
  profilePublicNav,
  profileDeveloperNav,
  {
    id: 'general',
    className: 'mt-auto',
    items: [
      // {
      //   id: 'settings-and-privacy',
      //   label: 'Settings & Privacy',
      //   href: nextProjectUrl.store('/profile/settings'),
      // },
      {
        // NOTE: logic inside of component
        id: 'support',
        label: 'Support',
      },
      {
        id: 'docs',
        label: 'Docs',
        external: true,
        href: 'https://docs.magic.store',
      },
      becomeDeveloperLink,
    ],
  },
];

export interface ProfileMenuDialogProps extends Dialog.DialogProps {
  onLogout?: () => void;
  onSupport?: () => void;
  user: NavLayoutUser;
}

export interface MagicIdBalanceItem {
  value: {
    amount: number;
    currency: string;
    formattedAmount: string;
    formattedAmountWithCurrency: string;
  };
  icon: {
    Icon: ComponentType<{ className?: string }>;
    colorClassName?: string;
  };
}

export const getMagicIdBalance = ({
  user,
  userKarma,
}: {
  user?: DeserializedUser | null;
  userKarma?: UserKarma;
}) => {
  const magicIdBalance = userKarma?.totalReward || 0;
  const magicIdBalanceCurrency = 'SQR';
  const formattedMagicIdBalanceAmount = formatReward(magicIdBalance);

  const magicIdUSDTBalance = userKarma?.usdt || 0;
  const magicIdUSDTBalanceCurrency = 'USDT';
  const formattedMagicIUSDTdBalanceAmount = formatReward(magicIdUSDTBalance);

  const sqrpAmount = userKarma?.totalSqrp || 0;
  const sqrpCurrency = 'SQRp';
  const formattedSqrpAmount = formatReward(sqrpAmount);

  const karmaAmount = user?.attributes.karma || 0;
  const karmaCurrency = 'Karma';
  const formattedKarmaAmount = formatReward(karmaAmount);

  return {
    sqr: {
      value: {
        amount: magicIdBalance,
        currency: magicIdBalanceCurrency,
        formattedAmount: formattedMagicIdBalanceAmount,
        formattedAmountWithCurrency: `${formattedMagicIdBalanceAmount} ${magicIdBalanceCurrency}`,
      },
      icon: {
        Icon: Sqr,
      },
    },
    usdt: {
      value: {
        amount: magicIdUSDTBalance,
        currency: magicIdUSDTBalanceCurrency,
        formattedAmount: formattedMagicIUSDTdBalanceAmount,
        formattedAmountWithCurrency: `${formattedMagicIUSDTdBalanceAmount} ${magicIdUSDTBalanceCurrency}`,
      },
      icon: {
        Icon: USDT,
      },
    },
    sqrp: {
      value: {
        amount: sqrpAmount,
        currency: sqrpCurrency,
        formattedAmount: formattedSqrpAmount,
        formattedAmountWithCurrency: `${formattedSqrpAmount} ${sqrpCurrency}`,
      },
      icon: {
        colorClassName: 'text-base-text-tertiary',
        Icon: MonoSqr,
      },
    },
    karma: {
      value: {
        amount: karmaAmount,
        currency: karmaCurrency,
        formattedAmount: formattedKarmaAmount,
        formattedAmountWithCurrency: `${formattedKarmaAmount} ${karmaCurrency}`,
      },
      icon: {
        Icon: Karma,
        colorClassName: 'text-base-text-pink',
      },
    },
  } satisfies Record<string, MagicIdBalanceItem>;
};

interface MagicIdBalanceStat {
  id: string;
  label: string;
  content: string;
  icon: MagicIdBalanceItem['icon'];
  subContent?: string;
  subIcon?: MagicIdBalanceItem['icon'];
}

export const useMagicIdBalanceStats = ({ user }: { user: DeserializedUser }) => {
  const { data: userKarma } = useSelfKarmaQuery(
    {
      searchParams: { period: 'daily' },
    },
    { enabled: Boolean(user) },
  );

  const magicIdBalance = getMagicIdBalance({ user, userKarma });

  const balances: MagicIdBalanceStat[] = useMemo(() => {
    if (!user) {
      return [];
    }

    return [
      {
        id: 'magic-id-balance',
        label: 'Magic ID Balance',
        className: 'col-span-full',
        content: magicIdBalance.sqr.value.formattedAmountWithCurrency,
        subContent: magicIdBalance.usdt.value.formattedAmountWithCurrency,
        icon: magicIdBalance.sqr.icon,
        subIcon: magicIdBalance.usdt.icon,
      },
      {
        id: 'sqrp-balance',
        label: 'SQRp Balance',
        content: magicIdBalance.sqrp.value.formattedAmount,
        icon: magicIdBalance.sqrp.icon,
      },
      {
        id: 'karma-balance',
        label: 'Karma Balance',
        content: magicIdBalance.karma.value.formattedAmount,
        icon: magicIdBalance.karma.icon,
      },
    ];
  }, [user, userKarma]);

  return balances;
};

export const ProfileMenuDialog = ({
  onLogout,
  onSupport,
  user,
  onOpenChange,
  ...props
}: ProfileMenuDialogProps) => {
  const balanceStats = useMagicIdBalanceStats({ user: user.data });
  const { showKyc } = useNavLayout();
  const { showGlobalInlineProfileMiddleBanner } = useUserAdSettings();
  const membershipClassNames = getMembershipClassNames(user.data.membershipTier.attributes.key);
  const isMaxTier = user.data.membershipTier.attributes.key === MEMBERSHIP_TIER_ID.PREMIUM_PLUS;

  const stats: (MagicIdBalanceStat & {
    className?: string;
    action?: {
      label: string;
      href: string;
      external?: boolean;
    };
  })[] = [
    ...balanceStats,
    {
      id: 'membership',
      label: 'Membership Plan',
      content: getMembershipName(user.data.membershipTier.attributes.key),
      className: 'col-span-full',
      icon: {
        Icon: Membership,
        colorClassName: membershipClassNames.text,
      },
      action: !isMaxTier
        ? {
            label: 'Upgrade',
            href: nextProjectUrl.store('/profile/membership/plans'),
            external: true,
          }
        : undefined,
    },
  ];

  const closeDialog = () => {
    onOpenChange?.(false);
  };

  const handleLogoutButtonClick = () => {
    onLogout?.();
    closeDialog();
  };

  const navItems = user.data.attributes.developerId
    ? profileMenuNav
    : profileMenuNav.filter((group) => group.id !== 'developer');

  const navItemClassName =
    'msq-sub-menu-link msq-sub-menu-link-mobile text-caption-sm-strong-m xl-msq:text-caption-sm-strong-d';

  const navGroupClassName = 'p-2 flex flex-col gap-1 xl-msq:p-4';

  return (
    <Dialog.Root onOpenChange={onOpenChange} {...props}>
      <Dialog.Portal>
        <Dialog.Overlay
          forceMount
          className="msq-dialog msq-dialog-mobile-menu-m xl-msq:msq-dialog-mobile-menu-d msq-dialog-animate"
        >
          <Dialog.Content
            forceMount
            aria-describedby={undefined}
            className={cn(
              // to hide divider when they on rounded corners of the panel
              'overflow-hidden',
              'z-20 msq-dialog-content sm-msq:msq-dialog-content-sm sm-msq:msq-dialog-content-right xl-msq:max-w-[400px]',
            )}
          >
            <div className="msq-dialog-header">
              <Dialog.Title className="msq-dialog-title ">Profile</Dialog.Title>

              <Dialog.Close asChild>
                <button className="msq-btn msq-btn-ghost msq-btn-icon-md msq-dialog-icon-button">
                  <CrossLg className="msq-btn msq-btn-icon-child" />
                </button>
              </Dialog.Close>
            </div>

            <div className="msq-dialog-body flex flex-col p-0">
              <div className="p-4 pb-3 xl-msq:px-6 xl-msq:pt-5 xl-msq:pb-1.5">
                <div className="flex items-center gap-2 xl-msq:gap-2.5 min-h-10 mb-4 xl-msq:mb-5">
                  <UserAvatar
                    className="msq-avatar-md"
                    src={user.data.attributes.icon}
                    membershipTier={user.data.membershipTier.attributes.key}
                  />

                  <div className="flex flex-col gap-0.5 xl-msq:gap-0 justify-center overflow-hidden">
                    <span className="text-caption-sm-strong-a truncate">
                      {user.data.attributes.displayedName || 'Noname'}
                    </span>

                    <span className="text-caption-xs-a text-base-text-tertiary truncate">
                      {user.data.attributes.name || 'Noname'}
                    </span>
                  </div>
                </div>

                {showKyc && (
                  <ProfileMenuDialogKyc
                    className="mb-5 xl-msq:mb-[1.625rem]"
                    user={user.data}
                    onOpenChange={onOpenChange}
                  />
                )}

                <ul className="grid grid-cols-2 gap-1 xl-msq:gap-1.5">
                  {stats.map(({ action, content, subContent, className, id, label, icon }) => {
                    const { Icon, colorClassName: iconClassName } = icon;

                    {
                      /* TODO: remove text-[10px] and make text-caption-xs-m works  at span with {label}*/
                    }

                    return (
                      <li
                        key={id}
                        className={cn(
                          'rounded-lg flex gap-2 items-center px-3 py-2 bg-base-bg-secondary',
                          className,
                        )}
                      >
                        <div
                          className={cn(
                            'flex gap-0.5 xl-msq:gap-1 pt-0.5 flex-col flex-1 overflow-hidden',
                          )}
                        >
                          <span className="text-caption-xs-m xl-msq:text-caption-xs-d text-base-text-tertiary truncate">
                            {label}
                          </span>
                          <span className="flex gap-1.5 min-h-6 items-center text-caption-sm-strong-m xl-msq:text-caption-sm-strong-d flex-1">
                            <span className="text-base-text-tertiary flex-shrink-0">
                              <Icon
                                className={cn('size-[0.875rem] xl-msq:size-4', iconClassName)}
                              />
                            </span>
                            <div className="flex gap-3">
                              <span className="truncate">{content}</span>
                              {subContent && (
                                <div className="flex items-center gap-1.5">
                                  <USDT className="size-[0.875rem] xl-msq:size-4" />
                                  <span className="truncate">{subContent}</span>
                                </div>
                              )}
                            </div>
                          </span>
                        </div>

                        {action && (
                          <NavLayoutLink
                            onClick={closeDialog}
                            // TODO: come up with better solution
                            href={action.href}
                            external={action.external}
                            className="msq-btn msq-btn-tertiary msq-btn-sm min-w-[7.5rem] mr-1 xl-msq:mr-2 xl-msq:min-w-[7.25rem]"
                          >
                            {action.label}
                          </NavLayoutLink>
                        )}
                      </li>
                    );
                  })}
                </ul>

                {showGlobalInlineProfileMiddleBanner && (
                  <GlobalInlineProfileMiddleBanner userId={user?.data.id} />
                )}
              </div>

              {/* shadow is used to make it pixel perfect because border is taking space */}
              <nav className="flex-1 flex flex-col [&>*:not(:first-child)]:shadow-inset-border-top [&>*:not(:first-child)]:shadow-base-border-light">
                {navItems.map((navGroup) => {
                  const isNavGroupHidden = navGroup.hidden && navGroup.hidden({ user });

                  if (isNavGroupHidden) {
                    return null;
                  }

                  return (
                    <div className={cn(navGroupClassName, navGroup.className)} key={navGroup.id}>
                      {navGroup.items.map((item) => {
                        const isItemHidden = item.hidden && item.hidden({ user });

                        if (isItemHidden) {
                          return null;
                        }

                        // TODO: make without condition
                        if (item.id === 'support') {
                          return (
                            <button
                              onClick={() => {
                                closeDialog();
                                onSupport?.();
                              }}
                              key={item.id}
                              className={navItemClassName}
                            >
                              {item.label}
                            </button>
                          );
                        }

                        return (
                          <NavLayoutLink
                            onClick={closeDialog}
                            key={item.id}
                            // TODO: come up with better solution
                            href={item.href}
                            external={item.external}
                            className={navItemClassName}
                          >
                            {item.label}
                          </NavLayoutLink>
                        );
                      })}
                    </div>
                  );
                })}

                <div className={navGroupClassName}>
                  <button onClick={handleLogoutButtonClick} className={navItemClassName}>
                    Logout
                  </button>
                </div>
              </nav>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
